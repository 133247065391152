.nice-btn-wechat {
  padding: 0;
  margin-bottom: 10px;
}

.nice-btn-wechat-icon {
  padding: 6px;
  width: 30px;
  height: 30px;
}
