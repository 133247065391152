.mdnice-searchbox {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 20;
  transition: all 0.15s ease-in-out;
  padding: 8px;
  max-width: calc(100% - 40px);
  background-color: white;
  height: 72px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;
}

.mdnice-searchbox-replace {
  margin-top: 8px;
  margin-left: 27px;
}

.mdnice-searchbox > div {
  display: flex;
  align-items: center;
}

.mdnice-searchbox div>:not(:last-child ) {
  margin-right: 3px;
}

.mdnice-searchbox div input {
  width: 160px;
}

.mdnice-searchbox .searchbox-button {
  background-color: white;
  outline: none;
  padding: 0px;
  border: none;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.mdnice-searchbox .searchbox-button:active{
  background-color: #e6f7ff;
}

.searchbox-icon {
  display: block;
  margin: auto;
  height: 24px;
  width: 24px;
  background-color: transparent;
}

.searchbox-icon-casefold {
  width: 18px;
}

.searchbox-icon-prev {
  transform: rotate(180deg);
}

.searchbox-icon-replace {
  width: 16px;
}

.searchbox-icon-fold {
  transform: rotate(-90deg);
  transition: all 0.2s ease-in-out;
}

.mdnice-searchbox[data-replace="true"]  .searchbox-icon-fold{
  transform: unset;
}

.searchbox-text-highlight {
  background-color: #91d5ff;
  line-height: 16px;
  padding: 3px 0px;
}

/* .mdnice-searchbox[data-active="false"] {
  max-height: 0px !important;
  padding-top: 0px;
  padding-bottom: 0px;
} */

.mdnice-searchbox[data-replace="false"] {
  height: 40px;
}

.mdnice-searchbox[data-replace="false"] .mdnice-searchbox-replace {
  visibility: hidden;
}
/* 
.mdnice-searchbox ~ div .CodeMirror-sizer {
  transition: all 0.15s ease-in-out;
} */

.mdnice-searchbox ~ div .CodeMirror-sizer {
  top: 40px;  
}

.mdnice-searchbox[data-replace="true"] ~ div .CodeMirror-sizer {
  top: 72px;
}


